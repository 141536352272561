@if (blogObj) {
  <article>
    <div class="breadcrumb-c">
      <nz-breadcrumb>
        <nz-breadcrumb-item>
          <a routerLink="/">
            Home
          </a>
        </nz-breadcrumb-item>

        <nz-breadcrumb-item>
          <a routerLink="/blog">Blog</a>
        </nz-breadcrumb-item>

        <nz-breadcrumb-item>
          Angular Developer Resume Example
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </div>

    <div class="d-flex jc-c gap-20">
      <div class="left-side-container">
        <div class="bl-menu-list">
          <div class="bl-menu-content">Content</div>
          <div class="bl-list">
            <ul>
              @for (item of menuItems; track $index) {
                <li (click)="scrollToElementById(item.id)">{{ item.text }}</li>
              }
            </ul>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="ShareIconsBannerRef"/>
      </div>

      <div class="quill-page-container article-item">
        <div id="selfcv-editor" [innerHTML]="blogObj.content.blog"></div>
      </div>
    </div>

  </article>

  <ng-template #ShareIconsBannerRef>
    <div class="social-share">
      <h2>Share this article</h2>
      <div>
        <i class="ph ph-facebook-logo" shareButton="facebook"></i>
        <i class="ph ph-x-logo" shareButton="x"></i>
        <i class="ph ph-linkedin-logo" shareButton="linkedin"></i>
        <i class="ph ph-telegram-logo" shareButton="telegram"></i>
      </div>
    </div>
  </ng-template>
}


