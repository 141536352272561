<div class="slefcv-banner">
  <a href="https://www.selfcv.com/" target="_blank">

    <div class="img-container">
      <div>
        <h2 class="mb-4">Need a Professional CV?</h2>
        <h3>Create, customize, and download your perfect CV with selfcv!</h3>

        <div class="mt-20 mb-20">
          <ul>
            <li> 📄 Choose from multiple templates and layouts.</li>
            <li> 🎯 Build your career with ease!</li>
          </ul>
        </div>

        <button class="sf-btn-primary">
          Explore selfcv Now
          <i class="ph-bold ph-sign-in"></i>
        </button>
      </div>
    </div>

  </a>
</div>

