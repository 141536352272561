<article>
  <form [formGroup]="blogForm" #form="ngForm" (ngSubmit)="onSubmit()">
    <div class="seo-form-container" formGroupName="seo">

      <!-- Title -->
      <div class="sf-input-wr">
        <label for="page-title">Title</label>

        <input
          id="page-title"
          class="sf-input"
          type="text"
          placeholder="Page SEO Title"
          formControlName="title"
          sfTrim
          [class.error]="form.submitted && blogForm.get('seo')?.get('title')?.errors">

        @if (form.submitted && blogForm.get('seo')?.get('title')?.errors; as error) {
          @if (error['required']) {
            <div class="sf-err-wr">
              <div class="sf-input-err">
                <i class="ph ph-warning"></i>
                Field Required
              </div>
            </div>
          }
        }
      </div>

      <!-- Image Url -->
      <div class="sf-input-wr">
        <label for="page-imgUrl">Image Url</label>

        <input
          id="page-imgUrl"
          class="sf-input"
          type="text"
          placeholder="Page SEO Image Url"
          formControlName="imgUrl"
          sfTrim
          [class.error]="form.submitted && blogForm.get('seo')?.get('imgUrl')?.errors">

        @if (form.submitted && blogForm.get('seo')?.get('imgUrl')?.errors; as error) {
          @if (error['required']) {
            <div class="sf-err-wr">
              <div class="sf-input-err">
                <i class="ph ph-warning"></i>
                Field Required
              </div>
            </div>
          }
        }
      </div>

      <!-- Url -->
      <div class="sf-input-wr">
        <label for="page-url">Url</label>

        <input
          id="page-url"
          class="sf-input"
          type="text"
          placeholder="Page SEO Url"
          formControlName="url"
          sfTrim
          [class.error]="form.submitted && blogForm.get('seo.url')?.errors">

        @if (form.submitted && blogForm.get('seo.url')?.errors; as error) {
          @if (error['required']) {
            <div class="sf-err-wr">
              <div class="sf-input-err">
                <i class="ph ph-warning"></i>
                Field Required
              </div>
            </div>
          }
        }
      </div>

      <!-- Route Key Name -->
      <div class="sf-input-wr">
        <label for="page-url">Route Keyword</label>

        <input
          id="page-route"
          class="sf-input"
          type="text"
          placeholder="Page Route Keyword"
          [formControl]="$any(blogForm.get('content.blogKeyWord'))"
          sfTrim
          [class.error]="form.submitted && blogForm.get('content.blogKeyWord')?.errors">

        @if (form.submitted && blogForm.get('content.blogKeyWord')?.errors; as error) {
          @if (error['required']) {
            <div class="sf-err-wr">
              <div class="sf-input-err">
                <i class="ph ph-warning"></i>
                Field Required
              </div>
            </div>
          }
        }
      </div>

      <!-- Description -->
      <div class="sf-input-wr">
        <label for="page-desc">Description</label>

        <textarea
          id="page-desc"
          class="sf-textarea"
          type="text"
          placeholder="Page SEO Description"
          formControlName="desc"
          sfTrim
          [class.error]="form.submitted && blogForm.get('seo.desc')?.errors"
        ></textarea>

        @if (form.submitted && blogForm.get('seo.desc')?.errors; as error) {
          @if (error['required']) {
            <div class="sf-err-wr">
              <div class="sf-input-err">
                <i class="ph ph-warning"></i>
                Field Required
              </div>
            </div>
          }
        }
      </div>

      <!-- Keywords -->
      <div class="sf-input-wr">
        <label for="page-keywords">Keywords</label>

        <textarea
          id="page-keywords"
          class="sf-textarea"
          type="text"
          placeholder="Page SEO Keywords"
          formControlName="keywords"
          sfTrim
        ></textarea>
      </div>

    </div>

    <nz-divider/>

    <div class="quill-page-container">
      <div id="selfcv-editor"></div>
    </div>


    <div class="d-flex jc-fe">
      <button class="sf-btn-primary w-160">
        <i class="ph ph-floppy-disk"></i>
        Save Article
      </button>
      <!--      @if (false) {-->
      <a
        class="sf-btn-fifth w-160 ml-16"
        [href]="'https://www.selfcv.com/career-blog/6-second-resume-format/'"
        target="_blank"
        rel="noopener">
        <i class="ph-bold ph-magnifying-glass"></i>
        Preview Post
      </a>
      <!--      }-->
    </div>
  </form>
</article>

