import {DOCUMENT} from "@angular/common";
import {SfTrimDirective} from "@app/shared/directives";
import {NzDividerComponent} from "ng-zorro-antd/divider";
import {BlogService} from "@app/shared/services/blog.service";
import {afterNextRender, Component, inject} from '@angular/core';
import {AppStateService} from "@app/shared/services/app-state.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";

declare const Quill: any;

const sanitizeUrl = (url: string) => {
  const safeUrlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return safeUrlPattern.test(url) ? url : 'about:blank';
}

@Component({
  selector: 'sf-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrl: './create-blog.component.scss',
  imports: [
    NzDividerComponent,
    ReactiveFormsModule,
    SfTrimDirective
  ]
})
export class CreateBlogComponent {
  private blogService = inject(BlogService);
  private readonly document = inject(DOCUMENT);
  private appStateService = inject(AppStateService);

  protected readonly blogForm = new FormGroup({
    seo: new FormGroup({
      title: new FormControl('', [Validators.required]),
      desc: new FormControl('', [Validators.required]),
      imgUrl: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required]),
      keywords: new FormControl(''),
    }),
    content: new FormGroup({
      blogKeyWord: new FormControl('', [Validators.required]),
      blog: new FormControl('', [Validators.required]),
    }),
  });

  private quill: any = null;

  constructor() {
    afterNextRender(() => {
      if (this.appStateService.isBrowser) {

        const checkQuill = setInterval(() => {
          if ((window as any).Quill) { // Check if Quill is available
            console.log('Quill is loaded!');
            clearInterval(checkQuill); // Stop checking

            setTimeout(() => {
              this.initQuill();
            }, 100);

          }
        }, 100);

      }
    });
  }

  private initQuill() {
    this.addFont();
    this.addLink();
    this.addDivider();
    this.addSelfcvBannerTxt();

    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'], // text styling
      ['blockquote', 'code-block'], // Block Formats

      ['link', 'image', 'video', 'formula'],

      [{'header': 1}, {'header': 2}, {'header': 3}], // custom button values

      [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}], // List Types

      [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript

      [{'indent': '-1'}, {'indent': '+1'}],          // Indentation

      [{'direction': 'rtl'}],                         // text direction

      [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
      // [{size: ['10px', '12px', '14px', '16px', '18px', '20px', '22px']}],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],

      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'font': ['poppins', 'serif', 'monospace', 'sans-serif']}],
      [{'align': []}],
      ['divider', 'selfcvBanner'], // Custom buttons

      ['clean']  // remove formatting button
    ];

    const options = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Start writing your article...',
      theme: 'snow',
    }

    this.quill = new Quill('#selfcv-editor', options);

    // Set the default font to 'Poppins'
    const editor = this.quill.root;
    editor.style.fontFamily = 'Poppins, sans-serif';
    editor.style.fontSize = '16px';

    const toolbar = this.quill.getModule('toolbar');

    toolbar.addHandler('image', () => {
      const imageUrl = prompt('Enter the image URL');

      if (imageUrl) {
        const range = this.quill.getSelection();
        this.quill.insertEmbed(range?.index || 0, 'image', imageUrl, Quill.sources.USER);
      }
    });

    toolbar.addHandler('divider', () => {
      const range = this.quill.getSelection(true);
      this.quill.insertEmbed(range.index, 'divider', true, Quill.sources.USER);
    });

    toolbar.addHandler('selfcvBanner', () => {
      this.insertBanner();
    });

    this.quill.on('text-change', () => {
      const htmlContent = this.quill.root.innerHTML;
      this.blogForm.get('content.blog')?.setValue(htmlContent, {emitEvent: false});
    });
  }

  private insertBanner() {
    const range = this.quill.getSelection(true);

    if (range) {
      this.quill.insertText(range.index, 'selfcv-banner-here', {
        'selfcvBanner': true,
        'attr-selfcv': true,
        color: '#ff0000'
      });
    }
  }

  private addDivider() {
    const BlockEmbed = Quill.import('blots/block/embed');

    class DividerBlot extends BlockEmbed {
      static blotName = 'divider';
      static tagName = 'hr';

      static create() {
        const node = super.create();
        node.setAttribute('class', 'ql-divider');
        return node;
      }
    }

    DividerBlot.blotName = 'divider';
    DividerBlot.tagName = 'hr';

    Quill.register(DividerBlot);

    setTimeout(() => {
      const toolbarElement = this.document.querySelector('.ql-toolbar .ql-divider');
      if (toolbarElement) {
        toolbarElement.innerHTML = '<i class="ph ph-minus fs-18"></i>';
      }
    }, 0);
  }

  private addLink() {
    // Get the default Link blot
    const Link = Quill.import('formats/link');

    // Extend it to customize the behavior
    class CustomLink extends Link {
      static create(value: string) {
        const node = super.create(value);
        node.setAttribute('href', sanitizeUrl(value));
        node.setAttribute('target', '_blank'); // Keep target for security
        node.setAttribute('rel', 'noopener');  // Only keep noopener
        return node;
      }
    }

    Quill.register(CustomLink, true);
  }

  private addFont() {
    // Get the default Link blot
    const Font = Quill.import('formats/font');

    Font.whitelist = ['poppins', 'serif', 'monospace', 'sans-serif'];
    Quill.register(Font, true);
  }

  private addSelfcvBannerTxt() {
    const Inline = Quill.import('blots/inline');

    class SelfcvBannerBlot extends Inline {
      static blotName = 'selfcvBanner';
      static tagName = 'span';

      static create(value: any) {
        const node = super.create(value);
        node.setAttribute('attr-selfcv', 'true'); // Custom attribute
        node.classList.add('selfcv-banner-here');
        return node;
      }

      static formats(node: HTMLElement) {
        return node.getAttribute('attr-selfcv') === 'true'; // Ensures correct formatting
      }
    }

    Quill.register(SelfcvBannerBlot);

    setTimeout(() => {
      const toolbarElement = this.document.querySelector('.ql-toolbar .ql-selfcvBanner');
      if (toolbarElement) {
        toolbarElement.innerHTML = 's';
        // @ts-ignore
        toolbarElement.title = "Insert selfcv banner";
      }
    }, 0);
  }

  protected onSubmit() {
    console.log(this.blogForm);
  }

}
