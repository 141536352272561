import {map} from "rxjs/operators";
import {ShareButtonDirective} from "ngx-sharebuttons";
import {Meta, Title} from "@angular/platform-browser";
import {Blog, BlogSeo} from "@app/shared/interfaces/blog";
import {DOCUMENT, NgTemplateOutlet} from "@angular/common";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {BlogService} from "@app/shared/services/blog.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AppStateService} from "@app/shared/services/app-state.service";
import {scrollToElement, updateCanonicalURL} from "@app/shared/helpers";
import {NzBreadCrumbComponent, NzBreadCrumbItemComponent} from "ng-zorro-antd/breadcrumb";
import {SelfcvBannerComponent} from "@app/shared/ui-kits/selfcv-banner/selfcv-banner.component";
import {afterNextRender, Component, DestroyRef, inject, OnInit, Renderer2, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'sf-show-blog',
  templateUrl: './show-blog.component.html',
  styleUrl: './show-blog.component.scss',
  imports: [
    NzBreadCrumbComponent,
    NzBreadCrumbItemComponent,
    RouterLink,
    NgTemplateOutlet,
    ShareButtonDirective
  ]
})
export class ShowBlogComponent implements OnInit {
  private readonly metaService = inject(Meta);
  private readonly titleService = inject(Title);
  private readonly document = inject(DOCUMENT);
  private readonly renderer = inject(Renderer2);
  private readonly destroyRef = inject(DestroyRef);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly viewContainerRef = inject(ViewContainerRef);

  private blogService = inject(BlogService);
  private appStateService = inject(AppStateService);

  protected blogObj: Blog | null = null;

  protected menuItems: { text: string; id: string }[] = [];

  constructor() {
    afterNextRender(() => {
      this.addSelfcvBanners();
    });
  }

  ngOnInit() {
    this.getBlog();
  }

  private getBlog() {
    this.blogService.getBlog()
      .pipe(
        map((blog) => {
          const profession = this.activatedRoute.snapshot.params['profession'];
          return blog.find((item) => item.content.blogKeyWord === profession);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        if (res) {
          this.blogObj = res;
          this.addMetaTags(res.seo);

          setTimeout(() => {
            const h2Elements = this.document.querySelector('.quill-page-container')
              ?.querySelectorAll('h2') || [];

            Array.from(h2Elements).forEach((element, index) => {
              element.setAttribute("id", `heading-${index + 1}`);
            });

            setTimeout(() => {
              if (h2Elements) {
                this.menuItems = Array.from(h2Elements).map((item) => {
                  return {text: item.innerText, id: item.id};
                });
              }

            });
          });
        }
      });
  }

  private addSelfcvBanners() {
    setTimeout(() => {
      const elements = Array.from(this.document.querySelectorAll('.selfcv-banner-here'))

      elements.forEach(element => {
        if (element.parentElement) {
          const c = this.viewContainerRef.createComponent(SelfcvBannerComponent);
          element.parentElement?.replaceWith(c.location.nativeElement);
        }
      });
    }, 100);
  }

  private addMetaTags(seo: BlogSeo) {
    const title = seo.title;
    const desc = seo.desc;
    const imgUrl = seo.imgUrl;
    const url = seo.url;
    const keywords = seo.keywords;

    this.titleService.setTitle(title);

    /* Update meta tags */
    this.metaService.updateTag({name: 'description', content: desc});
    this.metaService.updateTag({name: 'keywords', content: keywords});

    /* SEO Scripts Start */

    // this.initArticleLD(seo);

    /* SEO Scripts End */

    /*  Open Graph data  */
    this.metaService.updateTag({property: 'og:title', content: title});
    this.metaService.updateTag({property: 'og:description', content: desc});
    this.metaService.updateTag({property: 'og:url', content: url});
    this.metaService.updateTag({property: 'og:site_name', content: title});
    this.metaService.updateTag({property: 'og:image', content: imgUrl});
    this.metaService.updateTag({property: 'og:image:alt', content: 'image cropper'});

    /*  Twitter Card data  */
    this.metaService.updateTag({name: 'twitter:site', content: url});
    this.metaService.updateTag({name: 'twitter:title', content: title});
    this.metaService.updateTag({name: 'twitter:description', content: desc});
    this.metaService.updateTag({property: 'twitter:image:src', content: imgUrl});

    /* Other */
    this.metaService.updateTag({name: 'msapplication-TileImage', content: imgUrl});

    updateCanonicalURL(url, this.document, this.renderer);
  }

  private initArticleLD(seo: BlogSeo) {
    const title = seo.title;
    const desc = seo.desc;
    const imgUrl = seo.imgUrl;
    const url = seo.url;

    const blogLdIfExists = this.document.head.querySelector(`script[type="application/ld+json"][data-key="blog-ld"]`);
    if (blogLdIfExists) {
      this.renderer.removeChild(this.document.head, blogLdIfExists);
    }

    const jsonLdScript = this.renderer.createElement('script');
    this.renderer.setAttribute(jsonLdScript, 'data-key', 'blog-ld');
    jsonLdScript.text = this.getArticleJson({url, title, desc, imgUrl});
    jsonLdScript.type = 'application/ld+json';
    this.renderer.appendChild(this.document.head, jsonLdScript);

    const breadCrampScriptExists = this.document.head.querySelector(`script[type="application/ld+json"][data-key="breadcramp-ld"]`);
    if (breadCrampScriptExists) {
      this.renderer.removeChild(this.document.head, breadCrampScriptExists);
    }

    const breadCrampLdJson = this.renderer.createElement('script');
    this.renderer.setAttribute(breadCrampLdJson, 'data-key', 'breadcramp-ld');
    breadCrampLdJson.type = 'application/ld+json';
    breadCrampLdJson.text = this.getBreadCrampLdJson({title, url});

    this.renderer.appendChild(this.document.head, breadCrampLdJson);
  }

  private getBreadCrampLdJson(obj: { title: string, url: string }) {
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "resume cv builder",
          "item": "https://www.selfcv.com/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "career blog by selfcv",
          "item": "https://www.selfcv.com/career-blog/"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": obj.title,
          "item": obj.url + '/'
        }
      ]
    }
  }

  private getArticleJson(obj: { url: string, title: string, desc: string, imgUrl: string }) {
    return {
      "@context": "https://schema.org/",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": obj.url
      },
      "headline": obj.title,
      "description": obj.desc, // blog content here
      "image": obj.imgUrl,
      "author": {
        "@type": "Organization",
        "name": "Argishti Yeghiazaryan",
        "url": "https://www.linkedin.com/in/argishti-yeghiazaryan-99606692/"
      },
      "publisher": {
        "@type": "Organization",
        "name": "selfcv.com",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.selfcv.com/assets/img/logo.svg"
        }
      },
      "datePublished": "2024-10-26T08:00:00+08:00"
    }
  }

  protected scrollToElementById(id: string) {
    const offset = 120;

    const element = this.document.querySelector(`#${id}`) as HTMLElement;

    if (element) {
      scrollToElement(element, offset);
    }
  }

}


